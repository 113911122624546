import { createSlice } from '@reduxjs/toolkit';
// utils
import { ServerConfiguration } from '../../utils/serverConfig';
// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl;
const initialState = {
  isLoading: false,
  error: null,
  addCustomizationAction: null,
  updateCustomizationAction: null,
  deleteCustomizationDetailsAction: null,
};

const slice = createSlice({
  name: 'customization',
  initialState,
  reducers: {
    // Start Loading
    startLoading(state) {
      state.isLoading = true;
    },

    // has Error
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    addCustomizationSuccess(state, action) {
      state.isLoading = false;
      state.addCustomizationAction = action.payload;
    },

    updateCustomizationSuccess(state, action) {
      state.isLoading = false;
      state.updateCustomizationAction = action.payload;
    },


    deleteCustomizationDetailsSuccess(state, action) {
      state.isLoading = false;
      state.deleteCustomizationDetailsAction = action.payload;
    },

    clearCustomizationActionSuccess(state, action) {
      state.isLoading = false;
      state.deleteCustomizationDetailsAction = null;
      state.updateCustomizationAction = null;
      state.addCustomizationAction = null;
      
    },

  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function addCustomization(productData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_AddProductCustomizationDetail?PRODUCTVARIATIONID=${productData.cusvariationId}&PRODUCTID=${productData.productId}&CUSTOMIZABLE=${productData.cusoptionCustomize}&VALUE=${productData.cusoptionValue}&PRODUCTSTOCK=${productData.cusoptionStock}&PRODUCTCUSTOMIZATIONPRICE=${productData.cusoptionPrice}&USERID=${productData.userId}`
      );

      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addCustomizationSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.addCustomizationSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCustomization(productData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_UpdateProductCustomizationDetailWithCustomization?PRODUCTCUSTOMIZATIONID=${productData.cusoptionId}&VARIATIONID=${productData.cusvariationId}&CUSTOMIZABLE=${productData.cusoptionCustomize}&VALUE=${productData.cusoptionValue}&PRODUCTSTOCK=${productData.cusoptionStock}&PRODUCTCUSTOMIZATIONPRICE=${productData.cusoptionPrice}&USERID=${productData.userId}`
      );

      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateCustomizationSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updateCustomizationSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteCustomizationDetails(customizationId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_DeleteProductCustomizationDetails?PRODUCTCUSTOMIZATIONID=${customizationId}&USERID=${userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        console.log(data)
        console.log(JSON.parse(data.ReturnData))
        dispatch(slice.actions.deleteCustomizationDetailsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.deleteCustomizationDetailsSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearCustomizationAction() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearCustomizationActionSuccess([]));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------