import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import { ServerConfiguration } from '../../utils/serverConfig';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl;
const PROJECTID = ServerConfiguration.PROJECTID;

const initialState = {
  isLoading: false,
  error: null,
  deleteSuccess: null,
  endorseProductAction: null,
  products: [],
  pendingEndorse: [],
  product: null,
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
    totalItems: 0,
  },
  duplicationName:null,
  duplicationSKU: null,

  productReview: null,

  updateproduct: null,
  addproduct: null,
  addproductvariation: null,

  addproductmedia: null,
  deleteproductmedia: null,
  productdashboard: null,
  restockStatus: null,
  productByAvailability: null,
  dailyAvailability: null,

  productSpecialRate: null
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    getProductByAvailabilitySuccess(state, action) {
      state.isLoading = false;
      state.productByAvailability = action.payload;
    },

    getProductDailyAvailabilitySuccess(state, action) {
      state.isLoading = false;
      state.dailyAvailability = action.payload;
    },

    endorseProductSuccess(state, action) {
      state.isLoading = false;
      state.endorseProductAction = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    // DELETE PRODUCT
    deleteSuccess(state, action) {
      state.isLoading = false;
      state.deleteSuccess = action.payload.message;
      state.products = state.products.filter((product) => product.ProductID !== action.payload.data);
    },

    addProductSuccess(state, action) {
      state.isLoading = false;
      state.addproduct = action.payload;
    },

    updateProductSuccess(state, action) {
      state.isLoading = false;
      state.updateproduct = action.payload;
    },

    addProductVariationSuccess(state, action) {
      state.isLoading = false;
      state.addproductvariation = action.payload;
    },

    
    addProductSpecificationSuccess(state, action) {
      state.isLoading = false;
      state.addproductspecification = action.payload;
    },

    deleteProductSpecificationSuccess(state, action) {
      state.isLoading = false;
      state.deleteproductspecification = action.payload;
    },

    addProductMediaSuccess(state, action) {
      state.isLoading = false;
      state.addproductmedia = action.payload;
    },

    deleteProductMediaSuccess(state, action) {
      state.isLoading = false;
      state.deleteproductmedia = action.payload;
    },

    addProductReviewSuccess(state, action) {
      state.isLoading = false;
      state.productReview = action.payload;
    },

    checkDuplicationProductNameSuccess(state, action) {
      state.isLoading = false;
      state.duplicationName = action.payload;
    },

    checkDuplicationProductSKUSuccess(state, action) {
      state.isLoading = false;
      state.duplicationSKU = action.payload;
    },
 
    clearCheckingActionSuccess(state, action) {
      state.isLoading = false;
      state.duplicationName = null;
    },

    clearProductActionSuccess(state, action) {
      state.isLoading = false;
      state.addproductmedia = null;
      state.deleteproductmedia = null;
      state.endorseProductAction = null;
      state.deleteSuccess = null;
      state.productReview = null;

      state.productByAvailability = null;
      state.dailyAvailability = null;
      state.productSpecialRate = null;
      
    },

    getProductDashboardSuccess(state, action) {
      state.isLoading = false;
      state.productdashboard =  action.payload;
    },

    clearDashboard(state, action) {
      state.isLoading = false;
      state.productdashboard =  null;
    },

    updateDailyRestockSuccess(state, action) {
      state.isLoading = false;
      state.restockStatus =  action.payload;
    },

    updateProductSpecialRateSuccess(state, action) {
      state.isLoading = false;
      state.productSpecialRate =  action.payload;
    },

    resetReStockStatus(state, action) {
      state.isLoading = false;
      state.restockStatus =  null;
    },
    
    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const totalItems = sum(cart.map((product) => product.quantity));
      const subtotal = sum(cart.map((product) => product.price * product.quantity));
      state.checkout.cart = cart;
      state.checkout.discount = state.checkout.discount || 0;
      state.checkout.shipping = state.checkout.shipping || 0;
      state.checkout.billing = state.checkout.billing || null;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - state.checkout.discount;
      state.checkout.totalItems = totalItems;
    },

    addToCart(state, action) {
      const newProduct = action.payload;
      const isEmptyCart = !state.checkout.cart.length;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, newProduct];
      } else {
        state.checkout.cart = state.checkout.cart.map((product) => {
          const isExisted = product.id === newProduct.id;

          if (isExisted) {
            return {
              ...product,
              colors: uniq([...product.colors, ...newProduct.colors]),
              quantity: product.quantity + 1,
            };
          }

          return product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, newProduct], 'id');
      state.checkout.totalItems = sum(state.checkout.cart.map((product) => product.quantity));
    },

    deleteCart(state, action) {
      const updateCart = state.checkout.cart.filter((product) => product.id !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.cart = [];
      state.checkout.billing = null;
      state.checkout.activeStep = 0;
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.totalItems = 0;
    },

    backStep(state) {
      state.checkout.activeStep -= 1;
    },

    nextStep(state) {
      state.checkout.activeStep += 1;
    },

    gotoStep(state, action) {
      const step = action.payload;
      state.checkout.activeStep = step;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;

      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
    },


  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addToCart,
  resetCart,
  gotoStep,
  backStep,
  nextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  resetReStockStatus,
  clearDashboard
  
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts(type, typeValue, userId, productPage, page, project) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_ItemListByType?Type=${type}&TypeValue=${typeValue}&USERID=${userId}&PLATFORMTYPE=CMS&PRODUCTPERPAGE=${productPage}&PAGE=${page}&ProjectID=${PROJECTID}`
      );
      const json = await response.json();      
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
      dispatch(slice.actions.getProductsSuccess(JSON.parse(data.ReturnData)));
      }else {
        dispatch(slice.actions.getProductsSuccess([]));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductByAvailability(type, typeValue, userId, productPage, page, selectedDate) {
  console.log(`${url}Product_ItemListByTypeWithAvailability?Type=${type}&TypeValue=${typeValue}&USERID=${userId}&PLATFORMTYPE=CMS&PRODUCTPERPAGE=${productPage}&PAGE=${page}&ProjectID=${PROJECTID}&SELECTEDDATE=${selectedDate}`)
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_ItemListByTypeWithAvailability?Type=${type}&TypeValue=${typeValue}&USERID=${userId}&PLATFORMTYPE=CMS&PRODUCTPERPAGE=${productPage}&PAGE=${page}&ProjectID=${PROJECTID}&SELECTEDDATE=${selectedDate}`
      );
      const json = await response.json();      
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
      dispatch(slice.actions.getProductByAvailabilitySuccess(JSON.parse(data.ReturnData)));
      }else {
        dispatch(slice.actions.getProductByAvailabilitySuccess([]));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getProductDailyAvailability(startDate, endDate, merchantId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_ViewAvailabilityByDate?STARTDATE=${startDate}&ENDDATE=${endDate}&PROJECTID=${PROJECTID}&MERCHANTID=${merchantId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];

      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getProductDailyAvailabilitySuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getProductDailyAvailabilitySuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function endorseProduct(productId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_EndorseProducts?ProductIDs=${productId}&USERID=${userId}`
      );
      const json = await response.json();      
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
      dispatch(slice.actions.endorseProductSuccess(JSON.parse(data.ReturnData)));
      }else {
        dispatch(slice.actions.endorseProductSuccess([]));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(id, userId, project) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_ItemDetailByProductID?ProductID=${id}&USERID=${userId}&ProjectID=${PROJECTID}&PLATFORMTYPE=CMS`
      )
      const json = await response.json();      
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
      dispatch(slice.actions.getProductSuccess(JSON.parse(data.ReturnData)));
      }else {
        dispatch(slice.actions.getProductSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteProduct(id, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_DeleteProducts?ProductIDs=${id}&USERID=${userId}`);
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.deleteSuccess({
            message: data.ReturnMsg,
            data: JSON.parse(data.ReturnData)[0].ProductID
          }));
        }
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------ CHECKING ------------------------------------------


export function checkDuplicationProductName(name, projectId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_CheckDuplication?PRODUCTNAME=${name}&PROJECTID=${PROJECTID}`);
      const json = await response.json();      
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
      dispatch(slice.actions.checkDuplicationProductNameSuccess(JSON.parse(data.ReturnData)));
      }else {
        dispatch(slice.actions.checkDuplicationProductNameSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function checkDuplicationProductSKU(sku, userId, projectId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_CheckDuplicationBySKU?PRODUCTSKU=${sku}&USERID=${userId}&PROJECTID=${PROJECTID}`);
      const json = await response.json();      
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
      dispatch(slice.actions.checkDuplicationProductSKUSuccess(JSON.parse(data.ReturnData)));
      }else {
        dispatch(slice.actions.checkDuplicationProductSKUSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ------------------------------------------ ADD PRODUCT DATA ------------------------------------------

export function addProduct(productData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    return fetch(
      `${url}Product_AddProductByPost`
      , {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({          
          PRODUCTNAME: productData.name,
          PROJECTID,
          MERCHANT: productData.supplier,
          PRODUCTDESC: productData.description,
          PRODUCTCATEGORYID: productData.categoryId,
          PRODUCTHEIGHT: productData.height,
          PRODUCTWIDTH: productData.width,
          PRODUCTDEPTH: productData.depth,
          PRODUCTWEIGHT: productData.weight,
          PRODUCTSKU: productData.sku,
          PRODUCTBRAND: productData.brand,
          PRODUCTMODEL: productData.model,
          PRODUCTTAG: productData.tags,
          USERID: productData.userId
        })
      }
    )
      .then(response => response.json())
      .then(json => {
        const data = json[0];
        if (data && data.ReturnVal === 1) {
          dispatch(slice.actions.addProductSuccess(JSON.parse(data.ReturnData)));
        } else {
          dispatch(slice.actions.addProductSuccess([]));
          dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
        }
      })
      .catch(error => dispatch(slice.actions.hasError(error)));
  };
}


export function updateProduct(productData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    return fetch(
      `${url}Product_UpdateProductByPost`
      , {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({       
          PRODUCTID: productData.productId,    
          PRODUCTNAME: productData.name,
          PROJECTID,
          MERCHANT: productData.supplier,
          PRODUCTDESC: productData.description,
          PRODUCTCATEGORYID: productData.categoryId,
          PRODUCTHEIGHT: productData.height,
          PRODUCTWIDTH: productData.width,
          PRODUCTDEPTH: productData.depth,
          PRODUCTWEIGHT: productData.weight,
          PRODUCTSKU: productData.sku,
          PRODUCTBRAND: productData.brand,
          PRODUCTMODEL: productData.model,
          PRODUCTTAG: productData.tags,
          USERID: productData.userId
        })
      }
    )
      .then(response => response.json())
      .then(json => {
        const data = json[0];
        if (data && data.ReturnVal === 1) {
          dispatch(slice.actions.updateProductSuccess(JSON.parse(data.ReturnData)));
        } else {
          dispatch(slice.actions.updateProductSuccess([]));
          dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
        }
      })
      .catch(error => dispatch(slice.actions.hasError(error)));
  };
}


export function addProductMedia(productData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_AddProductMedia?PRODUCTID=${productData.productId}&PRODUCTVARIATIONDETAILID=${productData.variationId}&PRODUCTSLIDEORDER=${productData.order}&TYPE=${productData.types}&WIDTH=${productData.width}&HEIGHT=${productData.height}&IMAGENAME=${productData.filenames}&USERID=${productData.userId}`);
      const json = await response.json();      
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
      dispatch(slice.actions.addProductMediaSuccess(JSON.parse(data.ReturnData)));
      }else {
        dispatch(slice.actions.addProductMediaSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteProductMedia(userId,mediaId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_DeleteProductMedia?USERID=${userId}&PRODUCTMEDIAID=${mediaId}`);
      const json = await response.json();      
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
      dispatch(slice.actions.deleteProductMediaSuccess(JSON.parse(data.ReturnData)));
      }else {
        dispatch(slice.actions.deleteProductMediaSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addProductReview(reviewData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_AddReview?PARENTPRODUCTREVIEWID=${reviewData.parentReviewId}&PRODUCTID=${reviewData.productId}&USERID=${reviewData.userId}&PRODUCTREVIEWRATING=${reviewData.reviewRating}&PRODUCTREVIEWCOMMENT=${reviewData.comment}&REPLYPARENTID=${reviewData.parentId}`);
      const json = await response.json();      
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
      dispatch(slice.actions.addProductReviewSuccess(JSON.parse(data.ReturnData)));
      }else {
        dispatch(slice.actions.addProductReviewSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearProductAction() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
     
      dispatch(slice.actions.clearProductActionSuccess([]));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getProductDashboard(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Reporting_ProductDashboard?USERID=${userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];

      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getProductDashboardSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getProductDashboardSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateDailyRestock(productId, activeInd) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}UpdateDailyRestock?PRODUCTID=${productId}&ACTIVEIND=${activeInd}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];

      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateDailyRestockSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updateDailyRestockSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateProductSpecialRate(productData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_AddUpdateSpecialRate?PRODUCTID=${productData.productId}&PRODUCTVARIATIONDETAILID=${productData.variationDetailId}&SELECTEDDATE=${productData.selectedDate}&TOTALINVENTORY=${productData.totalInventory}&ISCUSTOMIZEDINVENTORY=${productData.isCustomizedInventory}&VARIATIONPRICE=${productData.variationPrice}&ISCUSTOMIZEDPRICE=${productData.isCustomizedPrice}&ISDAILYAVAILABLE=${productData.isDailyAvailable}&ISCUSTOMIZED=${productData.isCustomized}&MODIFYBY=${productData.modifyBy}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];

      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateProductSpecialRateSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updateProductSpecialRateSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}