import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl;
const PROJECTID = ServerConfiguration.PROJECTID;

const initialState = {
  isLoading: false,
  error: null,
  schoolList: [],
  classList: [],
  addUpdateSchoolAction: null,
  addUpdateClassAction: null,
  deleteSchoolAction: null,
  deleteClassAction: null,
};

const slice = createSlice({
  name: 'school',
  initialState,
  reducers: {
    // Start Loading
    startLoading(state) {
      state.isLoading = true;
    },

    // has Error
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    viewSchoolSuccess(state, action) {
      state.isLoading = false;
      state.schoolList = action.payload;
    },

    viewClassSuccess(state, action) {
      state.isLoading = false;
      state.classList = action.payload;
    },

    addUpdateSchoolSuccess(state, action) {
      state.isLoading = false;
      state.addUpdateSchoolAction = action.payload;
    },

    addUpdateClassSuccess(state, action) {
      state.isLoading = false;
      state.addUpdateClassAction = action.payload;
    },

    deleteSchoolSuccess(state, action) {
      state.isLoading = false;
      state.deleteSchoolAction = action.payload;
    },

    deleteClassSuccess(state, action) {
      state.isLoading = false;
      state.deleteClassAction = action.payload;
    },

    clearAllAction(state, action) {
      state.isLoading = false;
      state.addUpdateSchoolAction = null;
      state.addUpdateClassAction = null;
      state.deleteSchoolAction = null;
      state.deleteClassAction = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  clearAllAction
} = slice.actions;

// ----------------------------------------------------------------------

export function viewSchool(projectId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_SchoolList?PROJECTID=${PROJECTID}`
      );

      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.viewSchoolSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.viewSchoolSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function viewClass(projectId) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await fetch(
          `${url}General_ClassList?PROJECTID=${PROJECTID}`
        );
  
        const json = await response.json();
        const data = JSON.parse(json)[0];
        if (data && data.ReturnVal === 1) {
          dispatch(slice.actions.viewClassSuccess(JSON.parse(data.ReturnData)));
        } else {
          dispatch(slice.actions.viewClassSuccess([]));
        }
      } catch (error) {
        console.error(error);
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function addUpdateSchool(schoolData) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await fetch(
          `${url}General_AddUpdateSchoolList?MODIFYBY=${schoolData.userId}&PROJECTID=${PROJECTID}&SCHOOLNAME=${schoolData.schoolName}&SCHOOLID=${schoolData.schoolId}`
        );
  
        const data = await response.json();
        
        if (data && data[0] && data[0].ReturnVal === 1) {
          dispatch(slice.actions.addUpdateSchoolSuccess(data[0].ReturnData));
        } else {
          dispatch(slice.actions.addUpdateSchoolSuccess([]));
        }
      } catch (error) {
        console.error(error);
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  
export function addUpdateClass(classData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_AddUpdateClassList?MODIFYBY=${classData.userId}&PROJECTID=${PROJECTID}&CLASSNAME=${classData.className}&CLASSID=${classData.classId}&SCHOOLID=${classData.schoolId}&PAXNUM=${classData.numberOfPax}`
      );

      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addUpdateClassSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.addUpdateClassSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteSchool(userId, schoolId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_DeleteSchoolList?MODIFYBY=${userId}&SCHOOLID=${schoolId}&PROJECTID=${PROJECTID}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteSchoolSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.deleteSchoolSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteClass(userId, classId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_DeleteClassList?MODIFYBY=${userId}&CLASSID=${classId}&PROJECTID=${PROJECTID}`
      );
      const data = await response.json(); // Parse JSON response here
      
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteClassSuccess(data.ReturnData)); // Assuming ReturnData is an array or object
      } else {
        dispatch(slice.actions.deleteClassSuccess([])); // Provide a default value if needed
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------
