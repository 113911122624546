import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import categoryReducer from './slices/category';

import shopProfileReducer from './slices/shopProfile';
import feedbackReducer from './slices/feedback';
import specificationReducer from './slices/specification';
import variationReducer from './slices/variation';
import customizationReducer from './slices/customization';
import stockReducer from './slices/stock';
import orderReducer from './slices/order';
import promotionReducer from './slices/promotion';
import userReducer from './slices/user';
import generalReducer from './slices/general';
import propertyReducer from './slices/property';
import newsReducer from './slices/news';
import schoolReducer from './slices/school';
// import accountProfileReducer from './slices/accountProfile';
import accountShopInfoReducer from './slices/accountShopInfo';
import accommodationPromotionReducer from './slices/accommodationPromotion';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const accommodationPromotionPersistConfig = {
  key: 'accommodationPromotion',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout', 'promoListByID'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  category: categoryReducer,
  shopProfile: shopProfileReducer,
  feedback: feedbackReducer,
  specification: specificationReducer,
  variation: variationReducer,
  customization: customizationReducer,
  stock: stockReducer,
  order: orderReducer,
  promotion: promotionReducer,
  general: generalReducer,
  user: userReducer,
  property: propertyReducer,
  news: newsReducer,
  school: schoolReducer,
  // accountProfile: accountProfileReducer,
  accountShopInfo: accountShopInfoReducer,
  accommodationPromotion: persistReducer(accommodationPromotionPersistConfig, accommodationPromotionReducer),
});

export default rootReducer;
