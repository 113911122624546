import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl
const initialState = {
  isLoading: false,
  error: null,
  country: [],
  states: [],
  courier: [],
  usertype: [],
  paymentMethod: [],
};

const slice = createSlice({
  name: 'general',
  initialState,
  reducers: {

    // Start Loading
    startLoading(state) {
      state.isLoading = true;
    },

    // has Error
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // View Courier Service
    getCourierServiceSuccess(state, action) {
      state.isLoading = false;
      state.courier = action.payload;
    },

    getCountryListSuccess(state, action) {
      state.isLoading = false;
      state.country = action.payload;
    },

    getStateListSuccess(state, action) {
      state.isLoading = false;
      state.states = action.payload;
    },

    getUserTypeListSuccess(state, action) {
      state.isLoading = false;
      state.usertype = action.payload;
    },

    getPaymentMethodSuccess(state, action) {
      state.isLoading = false;
      state.paymentMethod = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getCourierService() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_ViewCourierService`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getCourierServiceSuccess(categories));
      } else {
        dispatch(slice.actions.getCourierServiceSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getCountryList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_CountryList`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getCountryListSuccess(categories));
      } else {
        dispatch(slice.actions.getCountryListSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getStateList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_ViewState`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getStateListSuccess(categories));
      } else {
        dispatch(slice.actions.getStateListSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserTypeList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_ViewUserType`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getUserTypeListSuccess(categories));
      } else {
        dispatch(slice.actions.getUserTypeListSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPaymentMethod() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_ViewPaymentMethod`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returnData = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getPaymentMethodSuccess(returnData));
      } else {
        dispatch(slice.actions.getPaymentMethodSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

