import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';
// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl;
const PROJECTID = ServerConfiguration.PROJECTID;

const initialState = {
  isLoading: false,
  error: null,
  shopProfile: [],
  openModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'shopProfile',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getShopProfileSuccess(state, action) {
      state.isLoading = false;
      state.shopProfile = action.payload;

    },

  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getShopProfile(Type, TypeValue, UserId, UserRoleId,ListPerPage, Page, ProjectId) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
          const response = await fetch(
            `${url}User_ProfileListByType?TYPE=${Type.Type}&TYPEVALUE=${Type.TypeValue}&USERID=${Type.UserId}&UserRoleID=${Type.UserRoleId}&LISTPERPAGE=${Type.ListPerPage}&PAGE=${Type.Page}&ProjectID=${PROJECTID}`
          );
          const json = await response.json();
          const data = JSON.parse(JSON.parse(json)[0].ReturnData);

          dispatch(slice.actions.getShopProfileSuccess(data[0]));
        } catch (error) {
          dispatch(slice.actions.hasError(error));
        }
      };

  };

