import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';
// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl;
const projectId = ServerConfiguration.PROJECTID;

const initialState = {
  isLoading: false,
  error: null,
  feedbackList: [],
};

const slice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET FEEDBACK LIST
    getFeedbackListSuccess(state, action) {
      state.isLoading = false;
      state.feedbackList = action.payload;
    },

    // ADD USER FEEDBACK
    addUserFeedbackSuccess(state, action) {
      state.isLoading = false;
      state.addedFeedback = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getFeedbackList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_ViewUserFeedbackWithProjectID?PROJECTID=${projectId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returnData = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getFeedbackListSuccess(returnData));
      } else {
        dispatch(slice.actions.getFeedbackListSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addUserFeedback(subject, body, name, email) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_AddUserFeedbackWithProjectID?SUBJECT=${subject}&BODY=${body}&NAME=${name}&EMAIL=${email}&PROJECTID=${projectId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returnData = JSON.parse(data.ReturnData);
        dispatch(slice.actions.addUserFeedbackSuccess(returnData));
      } else {
        dispatch(slice.actions.addUserFeedbackSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


