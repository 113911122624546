import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { DateTimePicker  , MobileTimePicker} from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFTimePicker.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
};

export default function RHFTimePicker({ name, helperText, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        // <DateTimePicker
        //   value={field.value}
        //   onChange={(newValue) => {
        //     field.onChange(newValue);
        //   }}
        //   inputFormat='dd/MM/yyyy hh:mm:ss'
        //   renderInput={(params) => (
        //     <TextField
        //       {...params}
        //       {...field}
        //       fullWidth
        //       error={!!error}
        //       helperText={error ? error?.message : helperText}
        //     />
        //   )}
        //   {...other}
        // />

        <MobileTimePicker
          // orientation="portrait"
          // label="For mobile"
          value={field.value}
          onChange={(newValue) => {
            field.onChange(newValue);
          }}
          inputFormat='HH:mm'
          renderInput={(params) =>   <TextField
            {...params}
            {...field}
            // fullWidth
            error={!!error}
            helperText={error ? error?.message : helperText} />}
        />
      )}
    />
  );
}
