
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  school: icon('ic_school'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'product module',
    items: [
      // { title: 'dashboard', path: PATH_DASHBOARD.product.root, icon: ICONS.dashboard },
      {
        title: 'product management',
        path: PATH_DASHBOARD.product.list,
        icon: ICONS.cart,
      },

      { title: 'stock management', path: PATH_DASHBOARD.stock.list, icon: ICONS.file },
      // { title: 'order management', path: PATH_DASHBOARD.order.list, icon: ICONS.booking },

      {
        title: 'transaction management',
        path: PATH_DASHBOARD.order.list,
        icon: ICONS.booking,
        // children: [
        //   { title: 'transaction list', path: PATH_DASHBOARD.order.list },
        // ]
      },

      {
        title: 'report management',
        path: PATH_DASHBOARD.order.report('summary'),
        icon: ICONS.folder,
        children: [
          { title: 'report summary', path: PATH_DASHBOARD.order.report('summary') },
          { title: 'report by product', path: PATH_DASHBOARD.order.report('product') },
          { title: 'report by class', path: PATH_DASHBOARD.order.report('class') },
          { title: 'product label', path: PATH_DASHBOARD.order.report('sticker') },
          { title: 'payment summary', path: PATH_DASHBOARD.order.report('payment') },
          // { title: 'overall report', path: PATH_DASHBOARD.order.list },
        ]
      },

      // { title: 'promotion management', path: PATH_DASHBOARD.promotion.list, icon: ICONS.ecommerce },
      {
        title: 'Settings', path: PATH_DASHBOARD.setting.category, icon: ICONS.lock,
        children: [
          { title: 'Canteens By Category', path: PATH_DASHBOARD.setting.category },
          { title: 'Classes By School', path: PATH_DASHBOARD.school.schoolList },
        ]
      },
      {
        title: 'feedbacks',
        path: PATH_DASHBOARD.feedback.root,
        icon: ICONS.chat,
      },
      {
        title: 'calendar management',
        path: PATH_DASHBOARD.product.calendar,
        icon: ICONS.calendar,
      },
      // { title: 'user management', path: PATH_DASHBOARD.product.userList, icon: ICONS.user },
    ],
  },

  {
    subheader: 'accomodation module',
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.accomodation.root, icon: ICONS.dashboard },
      {
        title: 'property management',
        path: PATH_DASHBOARD.accomodation.list,
        icon: ICONS.cart
      },
      {
        title: 'calendar management',
        path: PATH_DASHBOARD.accomodation.calendar,
        icon: ICONS.cart,
      },
      {
        title: 'booking management',
        path: PATH_DASHBOARD.accomodation.bookingList,
        icon: ICONS.booking,
      },
      // { title: 'user management', path: PATH_DASHBOARD.accomodation.userList, icon: ICONS.user },
    ],
  },

  {
    subheader: 'general',
    items: [
      { title: 'user management', path: PATH_DASHBOARD.user.userList, icon: ICONS.user },
      { title: 'ads management', path: PATH_DASHBOARD.news.newsList, icon: ICONS.blog },
      // { title: 'news management', path: PATH_DASHBOARD.news.newsList, icon: ICONS.blog },
    ],
  },

];

export default function getNavConfig(accessCategory, UserTypeID) {

  let accessPage = UserTypeID === 1 ? [...navConfig.filter((role) => role.subheader === 'general')] : [];

  if (accessCategory && accessCategory !== undefined) {
    accessCategory.split(",").forEach((page) => {
      switch (Number(page)) {
        case 1:
          accessPage = [...accessPage, ...navConfig.filter((role) => role.subheader === 'product module')]
          break;
        case 3:
          accessPage = [...accessPage, ...navConfig.filter((role) => role.subheader === 'accomodation module')]
          break;

        default:
          break;
      }
    })
  }

  return accessPage;
}
