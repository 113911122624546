import axios from 'axios';
import { ServerConfiguration } from './serverConfig';

const uploader = async (files, filenames, directory, ID) => {
  const formData = new FormData();
  formData.append("Directory", directory);
  formData.append("ID", ID);

  files.forEach((file, index) => {
    formData.append("upload[]", files[index]);
    formData.append("fileName[]", filenames[index]);
  });

  const config = { headers: { 'Content-Type': 'multipart/form-data' } };

  const resp = await axios.post(ServerConfiguration.uploadUrl, formData, config)
  try {
    return resp.status === 200
  }
  catch (err) {
    console.log(err)
    return false
  }
}

export default uploader;

