import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// utils
import { fToNow } from '../../../utils/formatTime';
// _mock_
// import { _notifications } from '../../../_mock/arrays';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { useAuthContext } from '../../../auth/useAuthContext';
import { useDispatch, useSelector } from '../../../redux/store';
import {
  viewNotificationByID,
  clearUserAction,
  updateNotificationStatus,
} from '../../../redux/slices/user';
// ----------------------------------------------------------------------

NotificationsDetails.propTypes = {
  handleClosePopover: PropTypes.func,
  newNotifications: PropTypes.array,
  oldNotifications: PropTypes.array,
  totalUnRead: PropTypes.number,
  isAllListing: PropTypes.bool,
};

export default function NotificationsDetails({
  handleClosePopover,
  totalUnRead,
  newNotifications,
  oldNotifications,
  isAllListing,
}) {
  const { user } = useAuthContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notificationAction } = useSelector((state) => state.user);

  useEffect(() => {
    if (notificationAction) {
      dispatch(clearUserAction());
      handleClosePopover();
      dispatch(viewNotificationByID(user?.UserID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationAction]);

  const handleNotification = (data) => {
    dispatch(updateNotificationStatus(data.NotificationID, user?.ProjectID));

    switch (data.NotificationUrl) {
      case 'event':
        break;

      case 'hotel':
        navigate(PATH_DASHBOARD.accomodation.bookingList);
        break;

      default:
        navigate(PATH_DASHBOARD.order.list);
        break;
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1">Notifications</Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            You have {totalUnRead} unread messages
          </Typography>
        </Box>

        {/* {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )} */}
      </Box>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
        <List
          disablePadding
          subheader={
            <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
              {!isAllListing ? 'New' : 'All Notification'}
            </ListSubheader>
          }
        >
          {newNotifications.map((data) => (
            <NotificationItem
              key={data.NotificationID}
              notification={data}
              handleNotification={() => handleNotification(data)}
              isAllListing={isAllListing}
            />
          ))}
        </List>

        {oldNotifications && oldNotifications.length > 0 && (
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {oldNotifications.map((data) => (
              <NotificationItem
                key={data.NotificationID}
                notification={data}
                handleNotification={() => handleNotification(data)}
              />
            ))}
          </List>
        )}
      </Scrollbar>

      <Divider sx={{ borderStyle: 'dashed' }} />

      {!isAllListing && (
        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            onClick={() => navigate(PATH_DASHBOARD.general.notificationList)}
          >
            View All
          </Button>
        </Box>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    NotificationID: PropTypes.number,
    avatar: PropTypes.node,
    type: PropTypes.string,
    NotificationTitle: PropTypes.string,
    NotificationStatus: PropTypes.string,
    NotificationDesc: PropTypes.string,
    CreatedDate: PropTypes.string,
  }),
  handleNotification: PropTypes.func,
  isAllListing: PropTypes.bool,
};

function NotificationItem({ notification, handleNotification, isAllListing }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.NotificationStatus === 'New' && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={handleNotification}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>

      <ListItemText
        disableTypography
        primary={title}
        secondary={
          <Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
            <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
            <Typography variant="caption">
              {fToNow(moment(notification.CreatedDate).format('YYYY-MM-DD'))}
            </Typography>
          </Stack>
        }
      />
      {notification.NotificationStatus === 'New' && isAllListing && (
        <Typography variant="caption" color="text.disabled">
          Unread
        </Typography>
      )}
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.NotificationTitle}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {notification.NotificationDesc && noCase(notification.NotificationDesc)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: (
        <img
          alt={notification.NotificationTitle}
          src="../../../assets/icons/notification/ic_package.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: (
        <img
          alt={notification.NotificationTitle}
          src="/assets/icons/notification/ic_shipping.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: (
        <img alt={notification.NotificationTitle} src="/assets/icons/notification/ic_mail.svg" />
      ),
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: (
        <img alt={notification.NotificationTitle} src="/assets/icons/notification/ic_chat.svg" />
      ),
      title,
    };
  }
  return {
    avatar: notification.avatar ? (
      <img alt={notification.NotificationTitle} src={notification.avatar} />
    ) : null,
    title,
  };
}
