import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl;
const PROJECTID = ServerConfiguration.PROJECTID;

const initialState = {
  isLoading: false,
  error: null,

  // general - option
  feature: [],
  mediaPlace: [],
  locationType: [],
  roomType: [],
  bedType: [],
  propertyType: [],
  propertyTypeList: [],
  hotelListByType: [],
  hotelListByID: [],
  hotelRoomByID: [],

  // general - option
  duplicationName: null,
  addBasic: null,
  updateBasic: null,
  addLocation: null,
  addFeature: null,
  addMedia: null,
  updateMedia: null,
  addAttraction: null,
  addAccount: null,

  addProperty: null,
  updateProperty: null,
  deleteFeature: null,
  deleteMedia: null,
  updateAttraction: null,
  deleteAttraction: null,
  addSpecialRate: null,
  deleteSpecialRate: null,

  updateRoomBasic: null,
  addRoomBasic: null,
  updateRoomOccu: null,
  updateStatusAction: null,
  endorsePropertyAction: null,

  deletePropertyAction: null,
  deleteRoomAction: null,
  updateOptionalSetting: null,
  roomRateByDate: [],
  hotelDashboard: [],
};

const slice = createSlice({
  name: 'property',
  initialState,
  reducers: {

    // Start Loading
    startLoading(state) {
      state.isLoading = true;
    },

    // has Error
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getHotelListByTypeSuccess(state, action) {
      state.isLoading = false;
      state.hotelListByType = action.payload;
    },

    getHotelListByIDSuccess(state, action) {
      state.isLoading = false;
      state.hotelListByID = action.payload;
    },

    getHotelRoomListByIDSuccess(state, action) {
      state.isLoading = false;
      state.hotelRoomByID = action.payload;
    },

    getAccomFeatureListSuccess(state, action) {
      state.isLoading = false;
      state.feature = action.payload;
    },

    getMediaPlaceListSuccess(state, action) {
      state.isLoading = false;
      state.mediaPlace = action.payload;
    },

    getLocationTypeListSuccess(state, action) {
      state.isLoading = false;
      state.locationType = action.payload;
    },

    getRoomTypeListSuccess(state, action) {
      state.isLoading = false;
      state.roomType = action.payload;
    },

    getBedTypeListSuccess(state, action) {
      state.isLoading = false;
      state.bedType = action.payload;
    },

    getPropertyTypeListSuccess(state, action) {
      state.isLoading = false;
      state.propertyType = action.payload;
      state.propertyTypeList = [{PropertyDescription : "All",PropertyType : "All",PropertyTypeID : 0} ,...action.payload]
    },

    checkDuplicationHotelNameSuccess(state, action) {
      state.isLoading = false;
      state.duplicationName = action.payload;
    },

    addPropertyBasicInfoSuccess(state, action) {
      state.isLoading = false;
      state.addBasic = action.payload;
    },

    updatePropertyBasicInfoSuccess(state, action) {
      state.isLoading = false;
      state.updateBasic = action.payload;
    },

    addPropertyLocationInfoSuccess(state, action) {
      state.isLoading = false;
      state.addLocation = action.payload;
    },

    addPropertyAccountInfoSuccess(state, action) {
      state.isLoading = false;
      state.addAccount = action.payload;
    },

    addPropertySuccess(state, action) {
      state.isLoading = false;
      state.addProperty = action.payload;
    },

    updatePropertySuccess(state, action) {
      state.isLoading = false;
      state.updateProperty = action.payload;
    },

    deletePropertySuccess(state, action) {
      state.isLoading = false;
      state.deletePropertyAction = action.payload;
    },

    addUpdateFeatureSuccess(state, action) {
      state.isLoading = false;
      state.addFeature = action.payload;
    },

    deleteFeatureSuccess(state, action) {
      state.isLoading = false;
      state.deleteFeature = action.payload;
    },

    addPropertyMediaSuccess(state, action) {
      state.isLoading = false;
      state.addMedia = action.payload;
    },

    updatePropertyMediaSuccess(state, action) {
      state.isLoading = false;
      state.updateMedia = action.payload;
    },

    deletePropertyMediaSuccess(state, action) {
      state.isLoading = false;
      state.deleteMedia = action.payload;
    },

    addNearbyAttractionSuccess(state, action) {
      state.isLoading = false;
      state.addAttraction = action.payload;
    },

    updateNearbyAttractionSuccess(state, action) {
      state.isLoading = false;
      state.updateAttraction = action.payload;
    },

    deleteNearbyAttractionSuccess(state, action) {
      state.isLoading = false;
      state.deleteAttraction = action.payload;
    },

    modifyRoomSpecialRateSuccess(state, action) {
      state.isLoading = false;
      state.addSpecialRate = action.payload;
    },

    viewRoomRateByDaySuccess(state, action) {
      state.isLoading = false;
      state.roomRateByDate = action.payload;
    },

    deleteRoomSpecialRateSuccess(state, action) {
      state.isLoading = false;
      state.deleteSpecialRate = action.payload;
    },

    deletePropertyRoomSuccess(state, action) {
      state.isLoading = false;
      state.deleteRoomAction = action.payload;
    },

    // addPropertyRoomSuccess(state, action) {
    //   state.isLoading = false;
    //   state.addPropertyRoom = action.payload;
    // },

    // updatePropertyRoomSuccess(state, action) {
    //   state.isLoading = false;
    //   state.updatePropertyRoom = action.payload;
    // },

    // deletePropertyRoomSuccess(state, action) {
    //   state.isLoading = false;
    //   state.deletePropertyRoom = action.payload;
    // },

    // deletePropertyRoomConfigSuccess(state, action) {
    //   state.isLoading = false;
    //   state.deletePropertyRoomConfig = action.payload;
    // },

    updatePropertyStatusSuccess(state, action) {
      state.isLoading = false;
      state.updateStatusAction = action.payload;
    },

    addPropertyRoomBasicSuccess(state, action) {
      state.isLoading = false;
      state.addRoomBasic = action.payload;
    },

    updatePropertyRoomBasicSuccess(state, action) {
      state.isLoading = false;
      state.updateRoomBasic = action.payload;
    },

    endorsePropertySuccess(state, action) {
      state.isLoading = false;
      state.endorsePropertyAction = action.payload;
    },

    updatePropertyRoomOccupancySuccess(state, action) {
      state.isLoading = false;
      state.updateRoomOccu = action.payload;
    },

    clearPropertyDataSuccess(state, action) {
      state.isLoading = false;
      state.error = null;

      state.hotelListByID = [];
    },

    clearPropertyRoomDataSuccess(state, action) {
      state.isLoading = false;
      state.error = null;

      state.hotelRoomByID = [];
    },

    updatePropertyOptionalSettingSuccess(state, action) {
      state.isLoading = false;
      state.updateOptionalSetting = action.payload;
    },

    getHotelDashboardSuccess(state, action) {
      state.isLoading = false;
      state.hotelDashboard = action.payload;
    },

    clearPropertyActionSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.duplicationName = null;

      state.deletePropertyAction = null;

      state.addBasic = null;
      state.updateBasic = null;

      state.addLocation = null;
      state.addFeature = null;
      state.deleteFeature = null;

      state.addMedia = null;
      state.updateMedia = null;
      state.deleteMedia = null;

      state.addAttraction = null;
      state.deleteAttraction = null;
      state.updateAttraction = null;
      state.addAccount = null;
      state.updateStatusAction = null;

      state.addProperty = null;
      state.updateProperty = null;
      state.addSpecialRate = null;
      state.deleteSpecialRate = null;
      state.addPropertyRoom = null;
      state.updatePropertyRoom = null;
      state.deletePropertyRoom = null;
      state.deletePropertyRoomConfig = null;

      state.updateRoomBasic = null;
      state.addRoomBasic = null;
      state.updateRoomOccu = null;
      state.endorsePropertyAction = null;
      state.deleteRoomAction = null;
      state.updateOptionalSetting = null;
      
      state.roomRateByDate = [];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  Category_ViewList,
} = slice.actions;

// ----------------------------------------------------------------------


// --------------------------------------------------------------------- Property General ------------------------------------------------------------------
export function getHotelListByType(propertyData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Hotel_ItemListByType?TYPE=${propertyData.type}&TYPEVALUE=${propertyData.value}&USERID=${propertyData.userId}&PROJECTID=${PROJECTID}&PRODUCTPERPAGE=${propertyData.productPerPage}&PAGE=${propertyData.page}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getHotelListByTypeSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getHotelListByTypeSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getHotelListByID(propertyData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Hotel_ItemListByID?HOTELID=${propertyData.hotelId}&MERCHANTID=${propertyData.userId}&PROJECTID=${PROJECTID}&REQROOMVARIATION=${propertyData.reqRoomVariation}&REQHOTELMEDIA=${propertyData.reqHotelMedia}&REQHOTELFEATURE=${propertyData.reqHotelFeature}&REQHOTELREVIEW=${propertyData.reqHotelReview}&REQHOTELATTRACTION=${propertyData.reqHotelAttraction}&REQROOMMEDIA=${propertyData.reqRoomMedia}&REQROOMFEATURE=${propertyData.reqRoomFeature}&REQROOMCONFIG=${propertyData.reqRoomConfig}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];

      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getHotelListByIDSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getHotelListByIDSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getHotelRoomListByID(propertyData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}HotelRoom_ItemListByID?ROOMID=${propertyData.roomId}&HOTELID=${propertyData.hotelId}&MERCHANTID=${propertyData.userId}&PROJECTID=${PROJECTID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];

      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getHotelRoomListByIDSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getHotelRoomListByIDSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAccomFeatureList(isRoomInd, isBasicInd) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_General_ViewFeature?ISROOM=${isRoomInd}&ISBASIC=${isBasicInd}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getAccomFeatureListSuccess(categories));
      } else {
        dispatch(slice.actions.getAccomFeatureListSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMediaPlaceList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_General_ViewMediaPlace`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getMediaPlaceListSuccess(categories));
      } else {
        dispatch(slice.actions.getMediaPlaceListSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLocationTypeList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_General_ViewLocationType`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getLocationTypeListSuccess(categories));
      } else {
        dispatch(slice.actions.getLocationTypeListSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRoomTypeList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_General_ViewRoomType`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getRoomTypeListSuccess(categories));
      } else {
        dispatch(slice.actions.getRoomTypeListSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPropertyTypeList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_General_ViewPropertyType`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getPropertyTypeListSuccess(categories));
      } else {
        dispatch(slice.actions.getPropertyTypeListSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBedTypeList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_General_ViewBedType`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getBedTypeListSuccess(categories));
      } else {
        dispatch(slice.actions.getBedTypeListSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// --------------------------------------------------------------------- Property Management ------------------------------------------------------------------


export function checkDuplicationHotelName(name, projectId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Accommodation_CheckDuplication?HOTELNAME=${name}&PROJECTID=${PROJECTID}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.checkDuplicationHotelNameSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.checkDuplicationHotelNameSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addPropertyBasicInfo(propertyData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Accommodation_AddBasicInfo?HOTELNAME=${propertyData.name}&HOTELDESCRIPTION=${propertyData.description}&PROPERTYTYPEID=${propertyData.propertyType}&CANCELLATIONPOLICY=${propertyData.cancelPolicy}&CHECKINSTARTTIME=${propertyData.checkinStartTime}&CHECKINENDTIME=${propertyData.checkinEndTime}&CHECKOUTSTARTTIME=${propertyData.checkoutStartTime}&CHECKOUTENDTIME=${propertyData.checkoutEndTime}&HOTELRATING=${propertyData.hotelRating}&MERCHANTID=${propertyData.userId}&PROJECTID=${PROJECTID}&MODIFYBY=${propertyData.userId}&CURRENTSTAGE=${propertyData.currentStage}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addPropertyBasicInfoSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.addPropertyBasicInfoSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePropertyBasicInfo(propertyData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_UpdateBasicInfo?HOTELID=${propertyData.hotelId}&HOTELNAME=${propertyData.name}&HOTELDESCRIPTION=${propertyData.description}&PROPERTYTYPEID=${propertyData.propertyType}&CANCELLATIONPOLICY=${propertyData.cancelPolicy}&CHECKINSTARTTIME=${propertyData.checkinStartTime}&CHECKINENDTIME=${propertyData.checkinEndTime}&CHECKOUTSTARTTIME=${propertyData.checkoutStartTime}&CHECKOUTENDTIME=${propertyData.checkoutEndTime}&HOTELRATING=${propertyData.hotelRating}&PROJECTID=${PROJECTID}&MODIFYBY=${propertyData.userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updatePropertyBasicInfoSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(data.ReturnSqlError));
        dispatch(slice.actions.updatePropertyBasicInfoSuccess([]));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addPropertyLocationInfo(propertyData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_AddLocationInfo?HOTELID=${propertyData.hotelId}&HOTELADDRESS1=${propertyData.add1}&HOTELADDRESS2=${propertyData.add2}&HOTELSTATE=${propertyData.state}&HOTELCITY=${propertyData.city}&HOTELPOSCODE=${propertyData.poscode}&HOTELCOUNTRYID=${propertyData.countryId}&HOTELLONGITUDE=${propertyData.longitude}&HOTELLATITUDE=${propertyData.latitude}&PROJECTID=${PROJECTID}&MODIFYBY=${propertyData.userId}&CURRENTSTAGE=${propertyData.currentStage}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addPropertyLocationInfoSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(data.ReturnSqlError));
        dispatch(slice.actions.addPropertyLocationInfoSuccess([]));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addPropertyAccountInfo(propertyData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_AddAccountInfo?HOTELID=${propertyData.hotelId}&OWNERFIRSTNAME=${propertyData.firstName}&OWNERLASTNAME=${propertyData.lastName}&HOTELCONTACT=${propertyData.contact}&HOTELEMAIL=${propertyData.email}&RECEIVEPAYMENTTYPE=${propertyData.method}&PROJECTID=${PROJECTID}&MODIFYBY=${propertyData.userId}&CURRENTSTAGE=${propertyData.currentStage}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addPropertyAccountInfoSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(data.ReturnSqlError));
        dispatch(slice.actions.addPropertyAccountInfoSuccess([]));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateProperty(propertyData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_UpdateHotel?HOTELID=${propertyData.hotelId}&HOTELNAME=${propertyData.name}&HOTELADDRESS1=${propertyData.add1}&HOTELADDRESS2=${propertyData.add2}&HOTELSTATE=${propertyData.state}&HOTELCITY=${propertyData.city}&HOTELPOSCODE=${propertyData.poscode}&HOTELCOUNTRYID=${propertyData.countryId}&HOTELLONGITUDE=${propertyData.longitude}&HOTELLATITUDE=${propertyData.latitude}&HOTELCONTACT=${propertyData.contact}&CANCELLATIONPOLICY=${propertyData.cancelPolicy}&CHECKINSTARTTIME=${propertyData.checkinStartTime}&CHECKINENDTIME=${propertyData.checkinEndTime}&CHECKOUTSTARTTIME=${propertyData.checkoutStartTime}&CHECKOUTENDTIME=${propertyData.checkoutEndTime}&HOTELEMAIL=${propertyData.email}&ISACTIVE=${propertyData.isActive}&HOTELDESCRIPTION=${propertyData.hotelDescription}&MERCHANTID=${propertyData.userId}&PROJECTID=${PROJECTID}&MODIFYBY=${propertyData.userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updatePropertySuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updatePropertySuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteProperty(hotelId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_DeleteHotel?HOTELID=${hotelId}&MODIFYBY=${userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deletePropertySuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.deletePropertySuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// --------------------------------------------------------------------- Feature Management ------------------------------------------------------------------



export function addUpdatePropertyFeature(propertyData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_AddUpdateFeature?HOTELID=${propertyData.hotelId}&ROOMID=${propertyData.roomId}&FEATURETYPEID=${propertyData.featureTypeId}&PROJECTID=${PROJECTID}&MODIFYBY=${propertyData.userId}&CURRENTSTAGE=${propertyData.currentStage}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addUpdateFeatureSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.addUpdateFeatureSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function deletePropertyFeature(hotelFeatureId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_DeleteFeature?HOTELFEATUREID=${hotelFeatureId}&MODIFYBY=${userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteFeatureSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.deleteFeatureSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// --------------------------------------------------------------------- Media Management ------------------------------------------------------------------

export function addPropertyMedia(propertyData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_AddMedia?HOTELID=${propertyData.hotelId}&ROOMID=${propertyData.roomId}&MEDIADESCRIPTIONID=${propertyData.mediaDescriptionId}&MEDIATYPE=${propertyData.mediaType}&MEDIAURL=${propertyData.mediaUrl}&PROJECTID=${PROJECTID}&MODIFYBY=${propertyData.userId}&CURRENTSTAGE=${propertyData.currentStage}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addPropertyMediaSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.addPropertyMediaSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePropertyMedia(mediaId, userId, hotelId, mediaDescriptionId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_UpdateMediaDescription?PRODUCTMEDIAID=${mediaId}&MODIFYBY=${userId}&HOTELID=${hotelId}&MEDIADESCRIPTIONID=${mediaDescriptionId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updatePropertyMediaSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updatePropertyMediaSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function deletePropertyMedia(mediaId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_DeleteMedia?PRODUCTMEDIAID=${mediaId}&MODIFYBY=${userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deletePropertyMediaSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.deletePropertyMediaSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// --------------------------------------------------------------------- Attraction Management ------------------------------------------------------------------

export function addNearbyAttraction(propertyData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_AddNearbyAttraction?HOTELID=${propertyData.hotelId}&PLACENAME=${propertyData.placeName}&PLACEDISTANCE=${propertyData.placeDistance}&DISTANCEUNIT=${propertyData.distanceUnit}&ATTRACTIONTYPEID=${propertyData.attractionTypeId}&PROJECTID=${PROJECTID}&MODIFYBY=${propertyData.userId}&CURRENTSTAGE=${propertyData.currentStage}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addNearbyAttractionSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.addNearbyAttractionSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateNearbyAttraction(propertyData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_UpdateNearbyAttraction?PLACEID=${propertyData.placeId}&PLACENAME=${propertyData.placeName}&PLACEDISTANCE=${propertyData.placeDistance}&DISTANCEUNIT=${propertyData.distanceUnit}&ATTRACTIONTYPEID=${propertyData.attractionTypeId}&PROJECTID=${PROJECTID}&MODIFYBY=${propertyData.userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateNearbyAttractionSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updateNearbyAttractionSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteNearbyAttraction(placeId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_DeleteNearbyAttraction?PLACEID=${placeId}&MODIFYBY=${userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteNearbyAttractionSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.deleteNearbyAttractionSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function viewRoomRateByDay(propertyData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_ViewSpecialRateByDate?HOTELID=${propertyData.hotelId}&ROOMID=${propertyData.roomId}&STARTDATE=${propertyData.startDate}&ENDDATE=${propertyData.endDate}&PROJECTID=${PROJECTID}&MERCHANTID=${propertyData.userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.viewRoomRateByDaySuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.viewRoomRateByDaySuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function modifyRoomSpecialRate(propertyData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_AddUpdateRoomSpecialRate?HOTELID=${propertyData.hotelId}&ROOMID=${propertyData.roomId}&ROOMPRICE=${propertyData.roomPrice}&SELECTEDDATE=${propertyData.selectedDate}&TOTALINVENTORY=${propertyData.totalInventory}&TOTALSLOTAVAILABLE=${propertyData.slotAvailable}&EXTRABEDCHARGE=${propertyData.extraBedCharges}&MINNIGHTSTAY=${propertyData.minNightStay}&MAXNIGHTSTAY=${propertyData.maxNightStay}
        &OCCUPANCYDISCOUNTRATE=${propertyData.occupancyDiscountRate}&ISCUSTOMIZEDISCOUNTBYOCCUPANCY=${propertyData.isCustomizeDiscountByOccupancy}&ISCUSTOMIZEAVAILABILITY=${propertyData.isCustomizeAvailability}&ISCUSTOMIZERATEPLAN=${propertyData.isCustomizeRatePlan}&MODIFYBY=${propertyData.userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.modifyRoomSpecialRateSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.modifyRoomSpecialRateSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteRoomSpecialRate(rateId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_DeleteRoomSpecialRate?SPECIALRATEID=${rateId}&MODIFYBY=${userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteRoomSpecialRateSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.deleteRoomSpecialRateSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addPropertyRoomBasic(propertyData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_AddRoomBasic?HOTELID=${propertyData.hotelId}&ROOMTYPEID=${propertyData.roomTypeId}&INTERNALROOMNAME=${propertyData.internalRoomName}&LICENSEID=${propertyData.licenseId}&TOTALINVENTORY=${propertyData.totalInventory}&MINPRICERATE=${propertyData.minRate}&MAXPRICERATE=${propertyData.maxRate}&WEEKLYDISCOUNTRATE=${propertyData.weekRate}&MONTHLYDISCOUNTRATE=${propertyData.monthRate}&NORMALPRICE=${propertyData.standardRate}&WEEKENDPRICE=${propertyData.weekendRate}&NUMBEROFROOM=${propertyData.roomAllotment}&ALLOTMENTALERT=${propertyData.allotmentAlert}&PROJECTID=${PROJECTID}&MODIFYBY=${propertyData.userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addPropertyRoomBasicSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.addPropertyRoomBasicSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePropertyRoomBasic(propertyData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_UpdateRoomBasic?ROOMID=${propertyData.roomId}&HOTELID=${propertyData.hotelId}&ROOMTYPEID=${propertyData.roomTypeId}&INTERNALROOMNAME=${propertyData.internalRoomName}&LICENSEID=${propertyData.licenseId}&TOTALINVENTORY=${propertyData.totalInventory}&MINPRICERATE=${propertyData.minRate}&MAXPRICERATE=${propertyData.maxRate}&WEEKLYDISCOUNTRATE=${propertyData.weekRate}&MONTHLYDISCOUNTRATE=${propertyData.monthRate}&NORMALPRICE=${propertyData.standardRate}&WEEKENDPRICE=${propertyData.weekendRate}&NUMBEROFROOM=${propertyData.roomAllotment}&ALLOTMENTALERT=${propertyData.allotmentAlert}&PROJECTID=${PROJECTID}&MODIFYBY=${propertyData.userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updatePropertyRoomBasicSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updatePropertyRoomBasicSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function endorseProperty(hotelId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_EndorsedHotel?HOTELID=${hotelId}&MODIFYBY=${userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.endorsePropertySuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.endorsePropertySuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePropertyRoomOccupancy(propertyData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_UpdateRoomOccupancy?HOTELID=${propertyData.hotelId}&ROOMID=${propertyData.roomId}&ISCHILDALLOW=${propertyData.isChildAllow}&MAXADULTPAX=${propertyData.adultPax}&MAXCHILDPAX=${propertyData.childPax}&EXTRABEDCHARGES=${propertyData.bedCharge}&MAXEXTRABED=${propertyData.maxBedNum}&ROOMSIZE=${propertyData.roomSize}&NUMBEROFBATHROOM=${propertyData.bathroomNum}&NUMBEROFBEDROOM=${propertyData.bedroomNum}&NUMBEROFBED=${propertyData.bedNum}&COMMONSPACENUM=${propertyData.commonSpaceNum}&ROOMCONFIG=${propertyData.roomConfig}&ADDBEDTYPEID=${propertyData.addBedTypeID}&ADDQUANTITY=${propertyData.addBedTypeQty}&ADDISBEDROOM=${propertyData.addIsBed}&ADDORDERID=${propertyData.addOrderID}&UPDATEBEDTYPEID=${propertyData.updateBedTypeID}&UPDATEQUANTITY=${propertyData.updateQty}&ROOMCOFIGID=${propertyData.updateConfigID}&DELETEDROOMCONFIGID=${propertyData.deleteConfigID}&PROJECTID=${PROJECTID}&MODIFYBY=${propertyData.userId}`
      );
      const json = await response.json();

      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updatePropertyRoomOccupancySuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updatePropertyRoomOccupancySuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function deletePropertyRoom(roomId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_DeleteRoom?ROOMID=${roomId}&MODIFYBY=${userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deletePropertyRoomSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.deletePropertyRoomSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deletePropertyRoomConfig(roomConfigId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_DeleteRoomConfig?ROOMCONFIGID=${roomConfigId}&MODIFYBY=${userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deletePropertyRoomConfigSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.deletePropertyRoomConfigSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePropertyStatus(projectId, hotelId, roomId, activeInd, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_UpdateHotelStatus?PROJECTID=${PROJECTID}&MODIFYBY=${userId}&HOTELID=${hotelId}&ROOMID=${roomId}&ACTIVEIND=${activeInd}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updatePropertyStatusSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updatePropertyStatusSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearPropertyAction() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearPropertyActionSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearPropertyData() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearPropertyDataSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function clearPropertyRoomData() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearPropertyRoomDataSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------------------- Settings -------------------------------------------------------------------------------------
export function updatePropertyOptionalSetting(modifyId, projectId, hotelId, totalFloor, totalBar, isSmokingAllow, totalRestaurant, totalRoom, roomVoltage, registerYear, licenseId, wifiCharge, breakfastCharge, parkingCharge, childBreakfastCharge, babyCotCharge, totalBusinessVenue, totalSpecialVenue, maxOccupancy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Accommodation_UpdateOptionalSetting?MODIFYBY=${modifyId}&PROJECTID=${PROJECTID}&HOTELID=${hotelId}&NUMOFFLOOR=${totalFloor}&NUMOFBAR=${totalBar}&ISSMOKINGALLOW=${isSmokingAllow}&NUMOFRESTAURANT=${totalRestaurant}&NUMOFROOM=${totalRoom}&ROOMVOLTAGE=${roomVoltage}&REGISTERYEAR=${registerYear}&LICENSEID=${licenseId}&WIFICHARGES=${wifiCharge}&BREAKFASTCHARGES=${breakfastCharge}&PARKINGCHARGES=${parkingCharge}&CHILDBREAKFASTCHARGES=${childBreakfastCharge}&BABYCOTCHARGES=${babyCotCharge}&NUMBUSINESSVENUE=${totalBusinessVenue}&NUMSPECIALVENUE=${totalSpecialVenue}&MAXEVENTOCCUPANCY=${maxOccupancy}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updatePropertyOptionalSettingSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updatePropertyOptionalSettingSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------------------- Analytic Report -------------------------------------------------------------------------------------
export function getHotelDashboard(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Reporting_HotelDashboard?USERID=${userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getHotelDashboardSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getHotelDashboardSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}