import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl
const PROJECTID = ServerConfiguration.PROJECTID
const initialState = {
  isLoading: false,
  error: null,
  orderStatus: [],
  orderList: [],
  courier: [],
  orderListByID: [],
  orderByAccessCategory: [],

  orderShipment: null,
  receiverAction: null,
  shipmentAction: null,
  updateTracking: null,
  updateStatus: null,
  updateDetailsStatus: null,
  reportSummary: null,
  updatedOrderStatusnUUID: null,
  updatedOrderDetailDate: null,
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {

    // Start Loading
    startLoading(state) {
      state.isLoading = true;
    },

    // has Error
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // View Order Status List
    getOrderStatusSuccess(state, action) {
      state.isLoading = false;
      state.orderStatus = action.payload;
    },

    // View Order Listing By Status
    getOrderListingByStatusSuccess(state, action) {
      state.isLoading = false;
      state.orderList = action.payload;
    },

    getOrderListingByIDSuccess(state, action) {
      state.isLoading = false;
      state.orderListByID = action.payload;
    },

    // View Courier Service
    getCourierServiceSuccess(state, action) {
      state.isLoading = false;
      state.courier = action.payload;
    },

    updateOrderReceiverDetailSuccess(state, action) {
      state.isLoading = false;
      state.receiverAction = action.payload;
    },

    createShipmentOrderSuccess(state, action) {
      state.isLoading = false;
      state.shipmentAction = action.payload;
    },

    updateShipmentTrackingSuccess(state, action) {
      state.isLoading = false;
      state.updateTracking = action.payload;
    },

    updateOrderTrackingStatusSuccess(state, action) {
      state.isLoading = false;
      state.updateStatus = action.payload;
    },

    updateOrderDetailsTrackingStatusSuccess(state, action) {
      state.isLoading = false;
      state.updateDetailsStatus = action.payload;
    },

    getOrderShipmentStatusSuccess(state, action) {
      state.isLoading = false;
      state.orderShipment = action.payload;
    },

    clearOrderListActionSuccess(state, action) {
      state.isLoading = false;
      state.receiverAction = null;
      state.shipmentAction = null;
      state.updateTracking = null;
      state.updateStatus = null;
      state.updateDetailsStatus = null;
      state.updatedOrderStatusnUUID = null;
      state.updatedOrderDetailDate = null;
    },

    viewOrderByAccessCategorySuccess(state, action) {
      state.isLoading = false;
      state.orderByAccessCategory = action.payload;
    },

    getReportSummarySuccess(state, action) {
      state.isLoading = false;
      state.reportSummary = action.payload;
    },

    updateOrderStatusnTransactionUUIDSuccess(state, action) {
      state.isLoading = false;
      state.updatedOrderStatusnUUID = action.payload;
    },

    updateOrderDetailDateSuccess(state, action) {
      state.isLoading = false;
      state.updatedOrderDetailDate = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  Category_ViewList,
} = slice.actions;

// ----------------------------------------------------------------------

export function getOrderStatus() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_ViewOrderStatus`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returndata = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getOrderStatusSuccess(returndata));
      } else {
        dispatch(slice.actions.getOrderStatusSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrderListingByStatus(status, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_ViewOrder?TRACKINGSTATUS=${status}&USERID=${userId}&PROJECTID=${PROJECTID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returndata = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getOrderListingByStatusSuccess(returndata));
      } else {
        dispatch(slice.actions.getOrderListingByStatusSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getOrderListingByID(orderId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_ViewOrderByOrderID?ORDERID=${orderId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returndata = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getOrderListingByIDSuccess(returndata));
      } else {
        dispatch(slice.actions.getOrderListingByIDSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCourierService() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_ViewCourierService`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returndata = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getCourierServiceSuccess(returndata));
      } else {
        dispatch(slice.actions.getCourierServiceSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateOrderReceiverDetail(orderData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_UpdateOrderUserDetails?ORDERID=${orderData.OrderID}&FIRSTNAME=${orderData.FirstName}&LASTNAME=${orderData.LastName}&USERCONTACTNO=${orderData.UserContactNo}&PICKUPIND=${orderData.PickUpInd}&USEREMAILADDRESS=${orderData.UserEmailAddress}&USERADDRESSLINE1=${orderData.UserAddressLine1}&USERADDRESSLINE2=${orderData.UserAddressLine2}&USERPOSCODE=${orderData.UserPoscode}&USERSTATE=${orderData.UserState}&USERCITY=${orderData.UserCity}&COUNTRYID=${orderData.CountryID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returndata = JSON.parse(data.ReturnData);
        dispatch(slice.actions.updateOrderReceiverDetailSuccess(returndata));
      } else {
        dispatch(slice.actions.updateOrderReceiverDetailSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createShipmentOrder(orderData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_CreateOrderShipment?PACKAGETYPE=${orderData.packageType}&WEIGHT=${orderData.parcelWeight}&LENGTH=${orderData.parcelLength}&WIDTH=${orderData.parcelWidth}&HEIGHT=${orderData.parcelHeight}&SENDER_CONTACTPERSON=${orderData.sendername}&SENDER_COMPANY=${orderData.sendercompany}&SENDER_CONTACTNO=${orderData.sendercontact}&SENDER_ADDLINE1=${orderData.senderadd1}&SENDER_ADDLINE2=${orderData.senderadd2}&SENDER_CITY=${orderData.sendercity}&SENDER_STATE=${orderData.senderstate}&SENDER_POSCODE=${orderData.senderposcode}&RECEIVER_FULLNAME=${orderData.FirstName}${orderData.LastName}&RECEIVER_CONTACTNO=${orderData.UserContactNo}&RECEIVER_ADDLINE1=${orderData.UserAddressLine1}&RECEIVER_ADDLINE2=${orderData.UserAddressLine2}&RECEIVER_CITY=${orderData.UserCity}&RECEIVER_STATE=${orderData.UserState}&RECEIVER_POSCODE=${orderData.UserPoscode}&RECEIVER_COUNTRYCODE=${orderData.countryCode}&PARCELQUANTITY=${orderData.parcelQuantity}&LOGISTICID=${orderData.logisticId}&ORDERNAME=${orderData.OrderName}&ORDERPRODUCTDETAILSID=${orderData.parcelDetailsID}&PROJECTID=${PROJECTID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returndata = JSON.parse(data.ReturnData);
        dispatch(slice.actions.createShipmentOrderSuccess(returndata));
      } else {
        dispatch(slice.actions.createShipmentOrderSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateShipmentTracking(orderData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_UpdateTrackingNumber?ORDERTRACKINGNUMBER=${orderData.trackingNumber}&LOGISTICID=${orderData.logisticId}&PDFLABEL=${orderData.pdfLabel}&ORDERPRODUCTDETAILSID=${orderData.parcelDetailsID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returndata = JSON.parse(data.ReturnData);
        dispatch(slice.actions.updateShipmentTrackingSuccess(returndata));
      } else {
        dispatch(slice.actions.updateShipmentTrackingSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateOrderTrackingStatus(orderId, trackingStatusId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_UpdateTrackingStatus?ORDERID=${orderId}&TRACKINGSTATUSID=${trackingStatusId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returndata = JSON.parse(data.ReturnData);
        dispatch(slice.actions.updateOrderTrackingStatusSuccess(returndata));
      } else {
        dispatch(slice.actions.updateOrderTrackingStatusSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function updateOrderDetailsTrackingStatus(orderDetailsId, trackingStatusId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_UpdateTrackingStatusByOrderProductDetailID?ORDERPRODUCTDETAILID=${orderDetailsId}&TRACKINGSTATUSID=${trackingStatusId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returndata = JSON.parse(data.ReturnData);
        dispatch(slice.actions.updateOrderDetailsTrackingStatusSuccess(returndata));
      } else {
        dispatch(slice.actions.updateOrderDetailsTrackingStatusSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function viewOrderShipmentStatus(trackingNumber, type) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_RequestOrderStatus?TRACKINGNUMBER=${trackingNumber}&PROJECTID=${PROJECTID}&TYPE=${type}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getOrderShipmentStatusSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getOrderShipmentStatusSuccess(JSON.parse(data.ReturnData)));
        // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function clearOrderListAction() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearOrderListActionSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function viewOrderByAccessCategory(trackingStatus, userId, accessCategoryId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_ViewOrderByAccessCategoryID?TRACKINGSTATUS=${trackingStatus}&PROJECTID=${PROJECTID}&USERID=${userId}&ACCESSCATEGORYID=${accessCategoryId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.viewOrderByAccessCategorySuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.viewOrderByAccessCategorySuccess(JSON.parse(data.ReturnData)));
        // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getReportSummary(userId, startDate, endDate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Reporting_OrderSummaryByType?USERID=${userId}&PROJECTID=${PROJECTID}&STARTDATE=${startDate}&ENDDATE=${endDate}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getReportSummarySuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getReportSummarySuccess(JSON.parse(data.ReturnData)));
        // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateOrderStatusnTransactionUUID(transactionUUID, transactionStatusID, paymentMethodID, billMsg, billTransaction, modifiedBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_UpdateOrderStatusnTransactionUUID?TRANSACTIONUUID=${transactionUUID}&TRANSACTIONSTATUSID=${transactionStatusID}&PAYMENTMETHODID=${paymentMethodID}&BILLMSG=${billMsg}&BILLTRANSACTION=${billTransaction}&MODIFIEDBY=${modifiedBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateOrderStatusnTransactionUUIDSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updateOrderStatusnTransactionUUIDSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateOrderDetailDate(orderProductDetailId, date, modifiedBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Order_UpdateOrderDetailDate?ORDERPRODUCTDETAILID=${orderProductDetailId}&DATE=${date}&MODIFIEDBY=${modifiedBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateOrderDetailDateSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updateOrderDetailDateSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}