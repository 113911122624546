import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl
const PROJECTID = ServerConfiguration.PROJECTID

const initialState = {
  isLoading: false,
  error: null,
  categories: [],
  categoriesByParent:[],
  variationByCategory: null,
  addCategory:null,
  updateCategory: null,
  deleteCategory: null,
  deleteVariation: null
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {

    // Start Loading
    startLoading(state) {
      state.isLoading = true;
    },

    // has Error
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // View Category List
    Category_ViewList(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    getCategoriesByParentSuccess(state, action) {
      state.isLoading = false;
      state.categoriesByParent = action.payload;
    },
    
    getVariationTypeByCategoryIDSuccess(state, action) {
      state.isLoading = false;
      state.variationByCategory = action.payload;
    },

    addProductCategorySuccess(state, action) {
      state.isLoading = false;
      state.addCategory = action.payload;
    },

    updateProductCategorySuccess(state, action) {
      state.isLoading = false;
      state.updateCategory = action.payload;
    },


    deleteProductCategorySuccess(state, action) {
      state.isLoading = false;
      state.deleteCategory = action.payload;
    },  
    
    deleteProductVariationSuccess(state, action) {
      state.isLoading = false;
      state.deleteVariation = action.payload;
    },  

    clearCategoryActionSuccess(state, action) {
      state.isLoading = false;
      state.addCategory = null;
      state.updateCategory = null;
      state.deleteCategory = null;
      state.deleteVariation = null;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  Category_ViewList,
} = slice.actions;

// ----------------------------------------------------------------------

export function getCategories2() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}/Product_CategoryListByAll2?projectID=${PROJECTID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.Category_ViewList(categories));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCategories() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}/Product_CategoryListByAll?projectID=${PROJECTID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.Category_ViewList(categories));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVariationTypeByCategoryID(categoryID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_ViewProductVariationByCategoryID?productCategoryID=${categoryID}`
      )
      const json = await response.json();      
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
      dispatch(slice.actions.getVariationTypeByCategoryIDSuccess(JSON.parse(data.ReturnData)));
      }else {
        dispatch(slice.actions.getVariationTypeByCategoryIDSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCategoriesByParent(projectID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}/Product_CategoryListing?projectID=${projectID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getCategoriesByParentSuccess(categories));
      } else {
        dispatch(slice.actions.getCategoriesByParentSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// export function addProductCategory(category,image, hierachyId,parentId, projectId, userId) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await fetch(
//         `${url}Product_AddProductCategory?PRODUCTCATEGORY=${category}&PRODUCTCATEGORYIMAGE=${image}&HIERARCHYID=${hierachyId}&PARENTPRODUCTCATEGORYID=${parentId}&PROJECTID=${PROJECTID}&USERID=${userId}`
//       )
//       const json = await response.json();      
//       const data = JSON.parse(json)[0];
//       if (data && data.ReturnVal === 1) {
//       dispatch(slice.actions.addProductCategorySuccess(JSON.parse(data.ReturnData)));
//       }else {
//         dispatch(slice.actions.addProductCategorySuccess([]));
//       }
//     } catch (error) {
//       console.error(error);
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function addProductCategory(category,image, hierachyId,parentId, projectId, userId,variationType,isCustomize) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_AddProductCategoryWithVariationType?PRODUCTCATEGORY=${category}&PRODUCTCATEGORYIMAGE=${image}&HIERARCHYID=${hierachyId}&PARENTPRODUCTCATEGORYID=${parentId}&PROJECTID=${PROJECTID}&USERID=${userId}&VARIATIONTYPE=${variationType}&ISCUSTOMIZEIND=${isCustomize}`
      )
      const json = await response.json();      
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
      dispatch(slice.actions.addProductCategorySuccess(JSON.parse(data.ReturnData)));
      }else {
        dispatch(slice.actions.addProductCategorySuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function updateProductCategory(categoryId, category, userId,variationId,variationType,isCustomize) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_UpdateProductCategoryWithVariation?PRODUCTCATEGORYID=${categoryId}&PRODUCTCATEGORY=${category}&USERID=${userId}&VARIATIONID=${variationId}&VARIATIONTYPE=${variationType}&ISCUSTOMIZEIND=${isCustomize}`
      )
      const json = await response.json();      
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
      dispatch(slice.actions.updateProductCategorySuccess(JSON.parse(data.ReturnData)));
      }else {
        dispatch(slice.actions.updateProductCategorySuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function deleteProductCategory(categoryId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_DeleteProductCategory?PRODUCTCATEGORYID=${categoryId}&USERID=${userId}`
      )
      const json = await response.json();      
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
      dispatch(slice.actions.deleteProductCategorySuccess(JSON.parse(data.ReturnData)));
      }else {
        dispatch(slice.actions.deleteProductCategorySuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteProductVariation(variationId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_DeleteProductVariation?PRODUCTVARIATIONID=${variationId}&USERID=${userId}`
      )
      const json = await response.json();      
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
      dispatch(slice.actions.deleteProductVariationSuccess(JSON.parse(data.ReturnData)));
      }else {
        dispatch(slice.actions.deleteProductVariationSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearCategoryAction() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
           dispatch(slice.actions.clearCategoryActionSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

